import { logError } from '../../utils/logger';
import { createTableRow } from '../../utils/table';
import { backendGet, getTokenFor } from '../api-client';
import { clearSession } from '../session';
import { giveLicensesModal } from './give-licenses-modal';
const openLicensesModal = (account) => {
    backendGet(`billing-summary-for?admin=${account.admin}`, (res) => {
        var _a;
        account.history = res;
        (_a = document.querySelector('accounts-section')) === null || _a === void 0 ? void 0 : _a.appendChild(giveLicensesModal(account));
    }, logError);
};
const impersonate = async (email) => {
    const token = await getTokenFor(email);
    if (!token) {
        return;
    }
    const superToken = localStorage.getItem('token');
    clearSession();
    window.open(`/?magic-link=${token}&super-token=${superToken}`, '_self');
};
export const accountsTable = (members) => {
    const result = document.createElement('accounts-table');
    const hiddenRows = JSON.parse(localStorage.getItem('hiddenRows') || '[]');
    if (!members.length) {
        const blankMembers = document.createElement('blank-members-state');
        blankMembers.textContent = 'No accounts were found under the specified parameters.';
        blankMembers.style.display = 'block';
        result.appendChild(blankMembers);
        return result;
    }
    const tableContainer = document.createElement('table-container');
    const table = document.createElement('table');
    const thead = document.createElement('thead');
    const headerRow = createTableRow([
        { text: 'team-creator', span: 2, cell: 'th' },
        { text: 'available-licenses-title', span: 1, cell: 'th' },
        { text: 'members-with-licenses-title', span: 1, cell: 'th' },
        { text: 'members-without-licenses-title', span: 1, cell: 'th' },
        { text: '', span: 1, cell: 'th' },
        { text: 'hide row', span: 1, cell: 'th' },
    ]);
    thead.appendChild(headerRow);
    const tbody = document.createElement('tbody');
    members.forEach((member) => {
        if (hiddenRows.includes(member.admin))
            return;
        const account = { admin: member.admin, licenses: member.credits };
        const memberRow = createTableRow([
            { text: member.admin, span: 2, cell: 'td' },
            { text: member.credits, span: 1, cell: 'td', style: 'center' },
            { text: member['members-with-licenses'], span: 1, cell: 'td', style: 'center' },
            { text: member['members-without-licenses'], span: 1, cell: 'td', style: 'center' },
            {
                icon: 'images/eye.png',
                span: 1,
                cell: 'td',
                style: 'center',
                tooltip: 'impersonate',
                onclick: (ev) => {
                    ev.stopPropagation();
                    impersonate(member.admin);
                },
            },
            {
                text: 'Hide',
                span: 1,
                cell: 'td',
                style: 'center',
                onclick: () => removeRowVisibility(member.admin, memberRow)
            },
        ], () => openLicensesModal(account));
        tbody.appendChild(memberRow);
    });
    table.append(thead, tbody);
    tableContainer.appendChild(table);
    result.appendChild(tableContainer);
    return result;
};
function removeRowVisibility(admin, row) {
    const hiddenRows = JSON.parse(localStorage.getItem('hiddenRows') || '[]');
    row.style.display = 'none';
    hiddenRows.push(admin);
    localStorage.setItem('hiddenRows', JSON.stringify(hiddenRows));
}
